import { inject } from '@angular/core';

import { UserApiService } from './user-api.service';
import { UserPermissionsService } from './user-permissions.service';
import { UserRole } from './user.model';
import { UserPermissionKey } from './user.permissions';

export const canDisplay = (key: UserPermissionKey) => {
  return () => inject(UserPermissionsService).hasPermission$(key, 'display');
};

export const canAdd = (key: UserPermissionKey) => {
  return () => inject(UserPermissionsService).canAdd$(key);
};

export const canDisplayDetail = (key: UserPermissionKey) => {
  return () => inject(UserPermissionsService).hasPermission$(key, 'detail');
};

export const hasAnyRole = (role: UserRole[]) => {
  return () => inject(UserApiService).hasAnyRole$(role);
};
